



























import { Component, Model, Prop, Vue } from "vue-property-decorator";
import { IconsType } from "@/types";
import { mdiCalendar } from "@mdi/js";
@Component
export default class PickerDate extends Vue {
  @Prop({ default: "" }) readonly max!: string;
  @Prop() readonly id!: string;
  @Prop({ default: "" }) readonly min!: string;
  @Prop({ default: "" }) readonly label!: string;
  @Prop({ default: false }) readonly dense!: string;
  @Model("change", { type: String }) readonly value!: string;
  get date() {
    return this.value;
  }
  set date(payload: string) {
    this.$emit("change", payload);
    this.modal = false;
  }
  private modal = false;
  private icons: IconsType = {
    calendar: mdiCalendar
  };
  public onPrependInnerClick() {
    document.getElementById(this.id)?.click();
  }
}
